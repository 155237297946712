@keyframes pulse_animation {
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.08);
    }
    50% {
        transform: scale(1);
    }
    60% {
        transform: scale(1);
    }
    70% {
        transform: scale(1.05);
    }
    80% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes ekg_animation {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    70% {
        stroke-dashoffset: -700;
    }
    99% {
        opacity: 0;
        stroke-dashoffset: -1000;
    }

    100% {
        stroke-dashoffset: -1000;
    }
}