
html, body {
    height: 100%;
    overflow: auto;
    background-color: transparent;
}

#root{
    height: 100%;
    overflow: auto;
}

.status_blink {
    animation: blinker 2s linear infinite;
}


@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.pointer {
    cursor: pointer;
}

.text-shadow{
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

body, html {
    height: 100%;
}

.stacked-image-parent {
    position: relative;
    top: 0;
    left: 0;
}
.stacked-image-1 {
    position: relative;
    top: 0;
    left: 0;
}
.stacked-image-2 {
    position: absolute;
    top: 0;
    left: 0;
}